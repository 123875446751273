// ($c==4 ? "garden" : ($c==5 || $c==8 ? "zone" : "exterior"));

// sekou_cate2.category と URLに使用するアルファベットの対応
const _categoryToAlphabet = {
  1: "style",
  2: "exterior",
  3: "reform",
  4: "garden",
  5: "zone",
  6: "exterior",
  7: "exterior",
  8: "zone",
  9: "exterior",
  10: "exterior",
  11: "tatemono",
} as const;

// sekou_cate2.category と日本語の対応
const _categoryToJapanese = {
  1: "新築外構",
  2: "デザイン",
  3: "リフォーム外構",
  4: "庭・ガーデン",
  5: "ゾーン",
  6: "エクステリア",
  7: "EX.デザイン",
  8: "水まわり",
  9: "EX.カラー",
  10: "EX.屋根カラー",
  11: "建物カラー",
} as const;

// 水まわりを最後に表示したい
export const categoryOrder = [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 8] as const;

export type category = keyof typeof _categoryToAlphabet;

export function categoryToAlphabet(category: category) {
  return _categoryToAlphabet[category];
}

export function categoryToJapanese(category: category) {
  return _categoryToJapanese[category];
}

export function getCategory() {
  return _categoryToJapanese;
}
